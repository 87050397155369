// @flow

import React, {type Element} from 'react';

const East = () => {
  return (
    <div style={{left:"100px",top:"50px", position:"relative"}}>
    <svg id="EastLogo" viewBox="0 0 39.6 53.65" >
      <path
        d="M19.8 53.65c.91 0 1.65-.71 1.65-1.58s-.74-1.58-1.65-1.58c-.91 0-1.65.71-1.65 1.58s.74 1.58 1.65 1.58"
        fill={'#f47b24'}
      />
      <path
        d="M39.6 19.04C39.6 8.52 30.74 0 19.8 0S0 8.52 0 19.04c0 7.48 4.5 13.92 11.02 17.03.65.44 1.32.84 2.03 1.2 4.75 2.41 6.17 9.31 6.58 12.69l.16 1.72c.01.11.01.19.01.19v-.04.04s0-.08.01-.19l.16-1.72c.42-3.38 1.83-10.27 6.58-12.69.68-.35 1.33-.74 1.96-1.16C35.08 33 39.6 26.54 39.6 19.04"
        fill={'#f47b24'}
      />
      <path
        d="M15.8 14.73c-1.24 0-2.29-.61-2.85-1.37l.39-.31c.61.73 1.41 1.19 2.47 1.19 1.24 0 2.16-.7 2.16-1.98 0-1.19-.8-1.95-2.41-1.95h-.64v-.45h.62c1.31 0 2.1-.63 2.1-1.71 0-.96-.79-1.6-1.8-1.6-.94 0-1.63.44-2.03 1.01l-.43-.26c.6-.79 1.44-1.23 2.49-1.23 1.23 0 2.31.8 2.31 2.01 0 .99-.54 1.67-1.4 1.99 1.01.25 1.73 1.05 1.73 2.14 0 1.52-1.03 2.52-2.71 2.52M22.08 12.07l-3.22 4.57h3.22v-4.57zm.52 5.03v2.25h-.52V17.1h-3.86v-.37l4.07-5.77h.31v5.67h1.34v.46H22.6zM26.9 25.86c-1.03 0-2.01-.52-2.62-1.18l.38-.32c.59.62 1.36 1.02 2.26 1.02 1.45 0 2.34-1.13 2.34-2.46 0-1.61-1-2.47-2.26-2.47-.93 0-1.57.53-1.96 1.27l-.4-.23.24-4.16h4.32v.46h-3.82l-.18 2.97c.44-.46 1.04-.8 1.87-.8 1.53 0 2.74 1.13 2.74 2.88 0 1.64-1.09 3.02-2.91 3.02M16.87 20.13c-1.34 0-2.24 1.02-2.24 2.39 0 1.4.79 2.45 2.25 2.45 1.31 0 2.26-.84 2.26-2.38.01-1.62-.96-2.46-2.27-2.46m-.04 8.24l-.39-.38c1.02-.91 1.88-2.19 2.34-3.33-.48.55-1.16.82-1.96.82-1.57 0-2.72-1.25-2.72-2.88 0-1.79 1.21-2.96 2.8-2.96 1.58 0 2.79 1.15 2.79 2.89 0 2.46-1.6 4.56-2.86 5.84M22.28 25.98v4.57h-3.22l3.22-4.57zm.21-1.1l-4.07 5.77v.37h3.86v2.25h.52v-2.25h1.34v-.46H22.8v-5.67h-.31zM9.75 16.09v5.19h2.88v-.45h-2.39v-1.96h2.11v-.46h-2.11v-1.86h2.25v-.46z"
        fill={'#fff'}
      />
    </svg>
    </div>
  );
};

const HellsKitchen = () => {
  return (
    <svg id="HellsKitchenLogo" viewBox="0 0 39.6 53.65">
      <path
        d="M19.8 53.65c.91 0 1.65-.71 1.65-1.58s-.74-1.58-1.65-1.58c-.91 0-1.65.71-1.65 1.58s.74 1.58 1.65 1.58"
        fill={'#f47b24'}
      />
      <path
        d="M39.6 19.04C39.6 8.52 30.74 0 19.8 0S0 8.52 0 19.04c0 7.48 4.5 13.92 11.02 17.03.65.44 1.32.84 2.03 1.2 4.75 2.41 6.17 9.31 6.58 12.69l.16 1.72c.01.11.01.19.01.19v-.04.04s0-.08.01-.19l.16-1.72c.42-3.38 1.83-10.27 6.58-12.69.68-.35 1.33-.74 1.96-1.16C35.08 33 39.6 26.54 39.6 19.04"
        fill={'#f47b24'}
      />
      <path
        d="M22.35 23.96c.22-.12 6.01-1.32 6.01-1.32l-6.42.02c-.1 0-.46 1.13-.48 1.18l-1.67 4.58c-.01.02-.14.02-.15 0l-1.67-4.58c-.02-.05-.38-1.19-.48-1.19l-6.43-.01s5.79 1.2 6.01 1.32c.39.22.23.66.09.63l-4.84-.9s4.18 1.38 5.09 1.8c.13.06.26.23.28.27.05.11 1.56 3.84 1.56 3.84.02.05.2.1.33.1h.26c.13 0 .31-.04.33-.1 0 0 1.51-3.72 1.56-3.84.02-.04.15-.21.28-.27.92-.43 5.09-1.8 5.09-1.8l-4.84.9c-.14.03-.3-.41.09-.63zM7.85 17.12l-1.18 2.52h-.18l-1.17-2.52c-.24-.52-.34-.65-.78-.65v-.18h1.87v.18c-.55 0-.61.16-.43.54l.78 1.65.78-1.65c.18-.4.18-.54-.42-.54v-.18h1.54v.18c-.48 0-.57.12-.81.65zm3.81 2.4v-.18c-.54 0-.63-.13-.63-.82v-1.24c0-.69.09-.81.63-.81v-.18H9.73v.18c.54 0 .63.12.63.81v1.24c0 .69-.09.82-.63.82v.18h1.93zm4.3 0v-1.34h-.19c-.16.72-.56 1.15-1.15 1.15-.87 0-1.11-.82-1.11-1.44s.23-1.44 1.11-1.44c.59 0 1 .44 1.15 1.15h.19v-1.33h-.18c-.04.12-.08.25-.13.33-.24-.27-.66-.46-1.04-.46-1.02 0-1.84.79-1.84 1.74 0 .96.82 1.75 1.84 1.75.38 0 .8-.2 1.05-.47.04.07.08.2.12.34h.18zm2.69-2.94v1.94c0 .69-.1.82-.63.82v.18h1.92v-.18c-.54 0-.63-.13-.63-.82v-1.94h.44c.7 0 .8.12.88.44l.05.18h.15l-.07-.91h-3.57l-.08.91h.17l.05-.18c.08-.32.18-.44.88-.44h.44zm6.41 1.32c0 .64-.31 1.44-1.21 1.44-.89 0-1.2-.8-1.2-1.44 0-.63.31-1.44 1.2-1.44.9 0 1.21.81 1.21 1.44zm.73 0c0-.95-.87-1.74-1.94-1.74-1.07 0-1.94.79-1.94 1.74 0 .96.87 1.75 1.94 1.75 1.07-.01 1.94-.79 1.94-1.75zm3.44-.67c0 .34-.19.64-.63.64h-.4v-1.29h.4c.43 0 .63.3.63.65zm-.87.94l1.08 1.35h1.09v-.18c-.42-.06-.7-.28-1.09-.71l-.44-.49c.6-.11.92-.49.92-.91 0-.53-.46-.94-1.31-.94H26.9v.18c.54 0 .63.12.63.81v1.24c0 .69-.09.82-.63.82v.18h1.93v-.18c-.54 0-.63-.13-.63-.82v-.35h.16zm6.7-1.7v-.18h-1.53v.18c.5 0 .52.14.23.53l-.6.82-.61-.82c-.28-.39-.24-.53.24-.53v-.18h-1.86v.18c.43 0 .58.16.94.66l.78 1.07v.32c0 .69-.09.82-.63.82v.18h1.92v-.18c-.53 0-.63-.13-.63-.82v-.33l.78-1.06c.35-.49.52-.66.97-.66z"
        fill={'#fff'}
      />
    </svg>
  );
};

const NoMad = () => {
  return (
    <svg id="NoMadLogo" viewBox="0 0 39.6 53.65">
      <path
        d="M19.8 53.65c.91 0 1.65-.71 1.65-1.58s-.74-1.58-1.65-1.58c-.91 0-1.65.71-1.65 1.58s.74 1.58 1.65 1.58"
        fill={'#f47b24'}
      />
      <path
        d="M39.6 19.04C39.6 8.52 30.74 0 19.8 0S0 8.52 0 19.04c0 7.48 4.5 13.92 11.02 17.03.65.44 1.32.84 2.03 1.2 4.75 2.41 6.17 9.31 6.58 12.69l.16 1.72c.01.11.01.19.01.19v-.04.04s0-.08.01-.19l.16-1.72c.42-3.38 1.83-10.27 6.58-12.69.68-.35 1.33-.74 1.96-1.16C35.08 33 39.6 26.54 39.6 19.04"
        fill={'#f47b24'}
      />
      <path
        d="M13.33 20.76c-.15 0-.22.15-.26.26-.31.89-.98 1.39-1.98 1.39-1.19 0-1.9-.98-1.9-2.96 0-.22 0-.43.02-.61h4.33c.17-1.59-.88-2.89-2.63-2.89-1.57 0-3.04 1.41-3.04 3.51 0 2.22 1.59 3.52 3.03 3.52.96 0 2.1-.38 2.65-1.65.03-.08.07-.23.07-.31-.03-.13-.11-.26-.29-.26zm-2.28-4.28c.69 0 1.26.4 1.26 1.22 0 .18-.05.41-.1.56H9.28c.24-1.45.97-1.78 1.77-1.78zm20.34 4.53c0 .08-.03.23-.07.31-.55 1.27-1.42 1.65-2.4 1.65-1.79 0-2.99-1.42-2.99-3.52s1.21-3.51 2.99-3.51c1.27 0 2.38.79 2.38 1.84 0 .48-.3.86-.76.86-.4 0-.73-.33-.73-.73 0-.36.25-.68.6-.73-.18-.36-.61-.71-1.29-.71-1.22 0-1.87.79-1.87 2.98 0 2.18.76 2.94 1.89 2.94.88 0 1.42-.48 1.74-1.37.03-.12.1-.26.26-.26.17 0 .25.13.25.25zm-12.91-5.06c-.79 0-1.52.43-1.97 1.19v-.71c0-.18-.12-.3-.25-.3-.15 0-.66.08-.93.08-.79 0-.89-.03-1.06-.03-.12 0-.25.1-.25.28 0 .18.13.3.25.3.17 0 .26-.07 1.06-.07v9.63c-.79 0-.89-.02-1.06-.02-.12 0-.25.12-.25.3s.13.28.25.28c.17 0 .26-.08 1.62-.08h.07c1.36 0 1.44.08 1.6.08.13 0 .25-.1.25-.28 0-.18-.12-.3-.25-.3-.17 0-.25.02-1.06.02v-4.55c.45.76 1.17 1.21 1.97 1.21 1.62 0 2.78-1.42 2.78-3.52.01-2.11-1.14-3.51-2.77-3.51zm-.03 6.47c-1.11 0-1.94-1.06-1.94-2.96s.83-2.96 1.94-2.96c.98 0 1.49.78 1.49 2.96 0 2.18-.51 2.96-1.49 2.96zm7.04.05c0 .18-.13.28-.25.28-.17 0-.26-.08-1.62-.08h-.07c-1.36 0-1.44.08-1.6.08-.13 0-.25-.1-.25-.28 0-.18.12-.3.25-.3.17 0 .25.02 1.06.02v-5.46c-.81 0-.89.05-1.06.05-.13 0-.25-.12-.25-.3s.12-.28.25-.28c.17 0 .25.02 1.06.02.26 0 .76-.07.93-.07.12 0 .25.12.25.3v5.74c.79 0 .89-.02 1.06-.02.11 0 .24.11.24.3zM21.57 14.94l2.11-2.1-2.11-2.1c-.18-.15 3.49 2.05 3.49 2.1 0 .05-3.7 2.27-3.49 2.1z"
        fill={'#fff'}
      />
    </svg>
  );
};

const NoHo = () => {
  return (
    <svg id="NoHoLogo"  viewBox="0 0 39.6 53.65">
    <path
      d="M19.8 53.65c.91 0 1.65-.71 1.65-1.58s-.74-1.58-1.65-1.58c-.91 0-1.65.71-1.65 1.58s.74 1.58 1.65 1.58"
      style={{
        fill: "#f47b24",
      }}
    />
    <path
      d="M39.6 19.04C39.6 8.52 30.74 0 19.8 0S0 8.52 0 19.04c0 7.48 4.5 13.92 11.02 17.03.65.44 1.32.84 2.03 1.2 4.75 2.41 6.17 9.31 6.58 12.69l.16 1.72c.01.11.01.19.01.19v-.04.04s0-.08.01-.19l.16-1.72c.42-3.38 1.83-10.27 6.58-12.69.68-.35 1.33-.74 1.96-1.16C35.08 33 39.6 26.54 39.6 19.04"
      style={{
        fill: "#f47b24",
      }}
    />
    <g>
      <path
        className="st0"
        d="M217.2 380.7c0 9.9-4.4 12.7-11.9 12.7s-11.9-2.8-11.9-12.7V273.4h-23v110.9c0 21.6 13.5 29.3 34.5 29.3h.8c21 0 34.5-7.9 34.5-29.3V273.4h-23v107.3zM284.5 273.4h-23V412H320v-20.6h-35.5zM388.3 339.9c11.1-1.4 15.4-11.9 15.4-21.8v-17.4c0-17.4-12.3-27.3-33.3-27.3h-36.2V412H370c21 0 34.5-9.9 34.5-27.3v-22.8c.1-10.7-4.1-20-16.2-22zm-31.1-46.7h12.9c6.5 0 10.7 3.4 10.7 10.7v16c0 7.3-4.2 10.7-10.7 10.7h-12.9v-37.4zm24.4 88.3c0 7.3-4.2 10.7-10.7 10.7h-13.7V350h13.7c6.5 0 10.7 3.4 10.7 10.7v20.8zM446.5 351.8h31.7v-20.2h-31.7v-38h37.9v-20.2h-60.8V412H485v-20.2h-38.5zM573.9 327.5v-24.8c0-21-13.9-29.3-34.9-29.3h-35.8V412h23v-55.3h11.5l17.6 55.3h23.4l-19.8-58.8c9.5-4.1 15-12.5 15-25.7zm-23-3.2c0 9.9-5.1 12.7-12.7 12.7h-12.1v-43.4h12.1c7.5 0 12.7 2.8 12.7 12.7v18zM665.2 327.5v-24.8c0-21-13.9-29.3-34.9-29.3h-35.8V412h23v-55.3H629l17.6 55.3H670l-19.8-58.8c9.5-4.1 15-12.5 15-25.7zm-23-3.2c0 9.9-5.1 12.7-12.7 12.7h-12.1v-43.4h12.1c7.5 0 12.7 2.8 12.7 12.7v18zM729.4 273.4l-15.5 64.4-15.4-64.4h-24.2l27.9 89.3V412h23v-49.3l27.9-89.3zM99.8 250.6c5 0 8.5-1.6 10-4.3V263c0 7.6-2.4 10.1-8.7 10.1-6.3 0-8.7-2.4-8.7-10.1v-5.1h-5.1v6.2c0 9 3.8 13.5 13.7 13.5h.2c10 0 13.7-4.5 13.7-13.5v-40.2c0-9-4-13.5-14-13.5h-.2c-10 0-14 4.5-14 13.5v14.7c-.1 8.1 5.5 12 13.1 12zM91.7 225c0-7.6 2.7-10.1 9-10.1s9 2.4 9 10.1v12.3c0 6.1-3.9 8.6-9 8.6-5.4 0-9-2.6-9-8.6V225z"
        style={{
          fill: "#fff",
        }}
        transform="matrix(.04883 0 0 .04883 .31 3.6)"
      />
      <path
        className="st0"
        d="M139 210.4h-2.9c-8.8 0-13 5.4-13 13.9v8.1c0 4.1.9 9.7 7.1 10.3-6.5.9-7.1 7.8-7.1 11.4v9.7c0 4.2 1 7.7 3.2 10.1L99.5 354 74 276.9h8.2l-1.5-4.6H56.1v-8.2c0-5.8 3.4-9.2 10.3-14.5 7-5.4 12.8-9.6 12.8-15.8v-10c0-9-4.7-13.5-13.9-13.5h-.2c-9.2 0-13.9 4.5-13.9 13.5v9h5V225c0-6.8 2.7-10.1 8.8-10.1s9.1 3.3 9.1 10.1v7.9c0 4.9-3.7 7.7-10.1 12.5-7.3 5.4-13 10.1-13 18.5V412h21.4v-84.8l20.8 64.6h12.9l20.8-64.6V412H149V273.5c2-2.4 2.9-5.7 2.9-9.8V254c0-3.6-.7-10.4-7.1-11.4 6.2-.7 7.1-6.2 7.1-10.3v-8.1c.1-8.4-4-13.8-12.9-13.8zm7.9 42.9v9.3c0 7.5-3.2 10.4-8.5 10.4h-1.8c-5.3 0-8.5-2.9-8.5-10.4v-9.3c0-5.9 3.2-8.2 8.5-8.2h1.8c5.3 0 8.5 2.3 8.5 8.2zm0-20.8c0 5.9-3.2 8.2-8.5 8.2h-1.8c-5.3 0-8.5-2.3-8.5-8.2v-7c0-7.5 3.2-10.4 8.5-10.4h1.8c5.3 0 8.5 2.9 8.5 10.4v7z"
        style={{
          fill: "#fff",
        }}
        transform="matrix(.04883 0 0 .04883 .31 3.6)"
      />
    </g>
  </svg>
  );
};

function renderMarker(neighborhood) {
  if (neighborhood) {
    switch (neighborhood.toLowerCase()) {
      case "hell's kitchen":
        return <HellsKitchen />;
      case 'nomad':
        return <NoMad />;
      case 'upper east side':
        return <East />;
      case 'noho':
        return <NoHo />;
    }
  }
}

type Props = {
  neighborhood: string,
};

export default ({neighborhood}: Props): Element<*> => (
  
  <div
    style={{
      cursor: 'pointer',
      height: '60px',
      width: '60px',
      marginTop: '-68px',
      marginRight: '-25px',
      transform: 'translate(-50%, -50%)',
    }}
  >
    {renderMarker(neighborhood)}
  </div>
);
