// @flow

import React, {Component} from 'react';
import {Block, Col} from 'jsxstyle';
import GoogleMap from 'google-map-react';
import Link from '@components/Link';
import Call from '@components/icons/Call';
import Paragraph from '@components/Paragraph';
import MapMarker from './MapMarker';

import {BLUE, WHITE} from '@styles/colors';

const defaultLat = 40.911794;
const defaultLng = -73.811678;

type Props = {
  address: string,
  phoneNumber: string,
  neighborhood: string,
  width: string | number,
  mediaQueries?: Object,
  smWidth?: string,
};

type State = {
  lat: null | number,
  lng: null | number,
};

function getLatLng(neighborhoodName) {
  switch (neighborhoodName.toLowerCase()) {
    case "hell's kitchen":
      return {
        lat: 40.7592463,
        lng: -73.9958739,
      };
    case 'nomad':
      return {
        lat: 40.74863,
        lng: -73.990279,
      };
    case 'upper east side':
      return {
        lat: 40.7824832,
        lng: -73.9479525,
      };
    case 'noho':
      return {
        lat: 40.7251077106792, 
        lng: -73.99440971749372,
      };
    default:
      return {
        lat: defaultLat,
        lng: defaultLng,
      };
  }
}

const {GATSBY_GOOGLE_MAPS_API_KEY} = process.env;

const K_CIRCLE_SIZE = 30;
const K_STICK_SIZE = 10;

export default class Map extends Component<Props, State> {
  state = {
    lat: null,
    lng: null,
  };

  componentWillUnmount() {
    if (this.state.lat && this.state.lng) {
      this.setState({
        lat: null,
        lng: null,
      });
    }
  }

  distanceToMouse = (
    markerPos: Object,
    mousePos: Object,
    markerProps: Object,
  ) => {
    const {x} = markerPos;
    const y = markerPos.y - K_STICK_SIZE - K_CIRCLE_SIZE / 2;
    const distanceKoef = markerProps.text === 'A' ? 1 : 1.5;
    return (
      distanceKoef *
      Math.sqrt(
        (x - mousePos.x) * (x - mousePos.x) +
          (y - mousePos.y) * (y - mousePos.y),
      )
    );
  };

  render() {
    const {
      width,
      mediaQueries,
      smWidth,
      neighborhood,
      address,
      phoneNumber,
    } = this.props;
    const {lat, lng} = getLatLng(neighborhood);
    return (
      <Col
        height={550}
        width={width}
        margin="0 auto"
        maxWidth={1360}
        mediaQueries={mediaQueries}
        smWidth={smWidth}
      >
        <Block height={phoneNumber ? '80%' : '100%'} width={'100%'}>
          <GoogleMap
            bootstrapURLKeys={{key: "AIzaSyAryFyTLfDCtby6ZmTeQpHWuyvyXLheL3o"}}
            defaultCenter={{
              lat: lat ? lat : defaultLat,
              lng: lng ? lng : defaultLng,
            }}
            defaultZoom={16}
            yesIWantToUseGoogleMapApiInternals
            hoverDistance={K_CIRCLE_SIZE / 2}
            distanceToMouse={this.distanceToMouse}
          >
            <MapMarker
              lat={lat ? lat : defaultLat}
              lng={lng ? lng : defaultLng}
              neighborhood={neighborhood}
            />
          </GoogleMap>
        </Block>
        {phoneNumber && (
          <Col
            background={BLUE}
            height={'20%'}
            alignItems="center"
            justifyContent="center"
          >
            <Paragraph color={WHITE} textTransform="uppercase">
              {address}
            </Paragraph>
            <Link
              href={`tel: ${phoneNumber}`}
              ariaLabel={`Call us at ${phoneNumber} to get more info`}
              style={{
                color: WHITE,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Call fill={WHITE} style={{marginRight: '5px'}} />
              {phoneNumber}
            </Link>
          </Col>
        )}
      </Col>
    );
  }
}
